// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-home-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/en/home.js" /* webpackChunkName: "component---src-pages-en-home-js" */),
  "component---src-pages-en-thank-you-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/en/thank-you.js" /* webpackChunkName: "component---src-pages-en-thank-you-js" */),
  "component---src-pages-hvala-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/hvala.js" /* webpackChunkName: "component---src-pages-hvala-js" */),
  "component---src-pages-index-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nama-js": () => import("/Users/ukozazenje/Sites/euss.rs.test/src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */)
}

